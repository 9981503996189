import React from 'react'
import Helmet from "react-helmet"

export default (props) => ( <
  Helmet htmlAttributes = {
    {
      lang: 'ja',
    }
  }
  title = {
    (props.title ? props.title + ' | ' : '') + "tipple music"
  }
  meta = {
    [{
      name: 'description',
      content: props.description || 'tipple music はオンラインでのパフォーマンスで収益化を実現するサービスです。'
    }]
  }
  link = {
    [{
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;500&family=Roboto:wght@100;300;400&display=swap'
    },
      {
        rel: 'stylesheet',
        href: 'https://fonts.googleapis.com/icon?family=Material+Icons'
    }]
  }
  />
)