import React from "react"

const Footer = () => {

  return (
    <a href='https://yomogi-design.site/' className='footer' target="_blank" rel="noreferrer noopener">powerd by Yomogi Design inc.</a>
  )
}

export default Footer
