import React from "react"
import { Link } from "gatsby"
import logo from '../images/tipple-logo.svg';
import { styled } from '@material-ui/core/styles';


const MainLogo = styled(Link)({
  display: 'block',
  margin: 'auto',
  marginBottom: '24px',
  maxWidth: '150px',
  height: 'auto'
});
const Header = () => {

  return (
    <MainLogo to="/"><img src={logo}/></MainLogo>
  )
}

export default Header
